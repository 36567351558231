/* 
.code:hover + .copy-icon{
    display: block;
}
.reff{
    position: relative;
}
.copy-icon{
    display: none;
}

.code:hover{
    cursor: pointer;
} */

.hexagon {
  position: relative;
  width: 123px;
  height: 127px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  /* clip-path: polygon(50% 4%, 75% 24%, 85% 74%, 60% 100%, 18% 75%, 21% 14%); */
  /* clip-path: polygon(48% 4%, 75% 36%, 84% 82%, 59% 100%, 17% 63%, 20% 3%); */
  margin: 0 auto;
  overflow: hidden;
  /* transform: skewY(-21deg)    */
}

.hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}

.small-hexagon {
  position: relative;
  width: 59px; /* Adjust the width as needed */
  height: 60px; /* Adjust the height as needed to achieve the desired aspect ratio */
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);

  /* clip-path: polygon(48% 4%, 76% 24%, 85% 74%, 60% 100%, 18% 75%, 21% 14%); */

  /* clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); */
  /* clip-path: polygon(48% 7%, 75% 39%, 84% 82%, 59% 100%, 17% 63%, 20% 9%);  */
  /* polygon(48% 4%, 75% 36%, 84% 82%, 59% 100%, 17% 63%, 20% 3%) */
  margin: 0 auto;
  overflow: hidden;
  /* transform: skewY(-21deg)    */
}

.small-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}

.main-hexagon {
  position: relative;
  width: 153px;
  height: 160px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  /* clip-path: polygon(50% 4%, 75% 24%, 85% 74%, 60% 100%, 18% 75%, 21% 14%); */
  /* clip-path: polygon(48% 4%, 75% 36%, 84% 82%, 59% 100%, 17% 63%, 20% 3%); */
  margin: 0 auto;
  overflow: hidden;
  /* transform: skewY(-21deg)    */
}

.main-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}

.MuiButtonBase-root.MuiCheckbox-root,
.MuiFormLabel-root.MuiInputLabel-root,
.MuiInputBase-root.MuiOutlinedInput-root {
  color: var(--body_color) !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ffb300 !important ;
}
.MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--border-color) !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

/* MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary Mui-focused MuiInputBase-formControl MuiInputBase-sizeSmall css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root */
.MuiOutlinedInput-notchedOutline {
  border-color: var(--conf-purchase);
}

.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  /* color: var(--body_color) !important; */
  background-color: var(--disabled-btn) !important;
}

.MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
  background-color: var(--main-body) !important;
  color: var(--body_color) !important;
}

.MuiSvgIcon-root.MuiSelect-icon {
  color: var(--body_color);
}

.MuiTableContainer-root {
  background-color: var(--main-body) !important;
}

.MuiTableBody-root .MuiTableRow-root {
  background-color: var(--main-body) !important;
}

.MuiTableCell-root {
  border-color: var(--border-color) !important;
}

.drop-hexagon {
  position: relative;
  width: 27px;
  height: 31px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  overflow: hidden;
}

.drop-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.powerpack_banner {
  margin-top: 100px;
  background-color: #f0f0f0;
  padding: "100px 30px 0px 30px";
  width: 100%; /* Use viewport width to make the banner span the entire width of the screen */
}

.actionpack_banner {
  background-image: url("../../assets/landpage/Action.svg");
  margin-top: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%; /* Use viewport width to make the banner span the entire width of the screen */
  height: 100vh; /* Use viewport height to make the banner span the entire height of the screen */
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;

  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: center;

  padding-top: 152px;
}

.tokenpack_banner {
  background-image: url("../../assets/landpage/Token.svg");
  margin-top: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%; /* Use viewport width to make the banner span the entire width of the screen */
  height: 100vh; /* Use viewport height to make the banner span the entire height of the screen */
  background-position: center 100%;
  position: relative;
  align-self: center;
  text-align: center;

  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: center;

  padding-top: 152px;
}

.cryptopack_banner {
  background: black;
  padding: "100px 30px 0px 30px";
  width: 100%;
}

.power-link {
  color: #ffb300;
}

.power-link:hover {
  color: #ffb300;
  opacity: 0.8;
}

.crypto-link {
  color: #ffb300;
}

.crypto-link:hover {
  color: #ffce5d;
}

.hive-link:hover {
  color: #ffd000 !important;
}
@media (max-width: 767px) {
  .actionpack_banner {
    /* margin-top: 50px; */
    width: 100%;
    height: 228px;
    background-position: center 100%;
    background-size: cover;
    position: relative;
    gap: 30px;
    padding-top: 40px;
    font-size: 12px;
  }

  .tokenpack_banner {
    /* margin-top: 50px; */
    width: 100%;
    height: 228px;
    background-position: center 100%;
    background-size: cover;
    position: relative;
    gap: 30px;
    padding-top: 40px;
    font-size: 12px;
  }
}
