:root {
  --main-body: #181a20;
  --body_background: #181a21;
  --body_color: #e0e0e0;
  --dark_color: #282828;
  --dark_text: #fff;
  --err-clr: rgba(246, 96, 54, 0.5);
  --light-gr-color: rgba(226, 220, 220, 0.5);
  --disabled-btn: #4e4e4e9f;
  --border-color: #2c2c2c;
  --conf-purchase: rgba(212, 212, 212, 0.685);
  --dash-bg: #151414;
}

[data-bs-theme="light"] {
  --main-body: #fff;
  --body_background: #fff;
  --body_color: #000;
  --dark_color: #5f5f5f;
  --dark_text: #000;
  --err-clr: rgba(246, 96, 54, 0.2);
  --light-gr-color: rgba(18, 18, 18, 0.4);
  --disabled-btn: #f5f5f5;
  --border-color: #5f5f5f36;
  --conf-purchase: rgba(96, 96, 96, 0.5);
  --dash-bg: #f6f6f6;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-body);
  color: var(--body_color) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

.flex-align-start {
  align-items: start;
}

div.flex-align-center {
  align-items: center;
}

div.flex-align-stretch {
  align-items: stretch;
}

div.flex-justify-center {
  justify-content: center;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-direction-column {
  flex-direction: column;
}
