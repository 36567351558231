.hive-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 50px;
  background-color: transparent;
  color: var(--body_color) !important;
}

.honeycomb-container {
  width: 700px;
  /* height: 650px; */
  padding: 20px;
}

.flex-direction-column {
  flex-direction: column;
}

.lh_32x {
  line-height: 32px;
  overflow-wrap: break-word;
}

.font_10x {
  font-size: 10px;
}

.font_13x {
  font-size: 13px;
}

.font_15x {
  font-size: 15px;
}

.font_17x {
  font-size: 17px;
}

.font_20x {
  font-size: 20px;
}

.font_40x {
  font-size: 40px;
}

.text-left {
  text-align: left;
}

.public-hexagon {
  position: relative;
  width: 140px;
  height: 144px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  margin: 0 auto;
  overflow: hidden;
}

.public-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}

.bee-hexagon {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: transparent;
  clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
  /* margin: 0 auto; */
  overflow: hidden;
}

.bee-hexagon img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}

.elipse-img {
  position: relative;
  width: 57px;
  height: 59px;
  background-color: transparent;
  clip-path: ellipse(30% 50% at 50% 50%);
  transform: rotate(30deg);
  /* margin: 0 auto; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.elipse-img img {
  display: block;
  width: 50%;
  height: 50%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
}

.MuiRating-iconEmpty {
  color: var(--body_color) !important;
}

.post-input .MuiInputBase-root.MuiOutlinedInput-root {
  color: var(--body_color) !important;
  border-radius: 2px !important;
  border: 1px solid var(--border-color) !important;
  background-color: var(--body_background) !important;
}

.post-input .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

@media screen and (min-width: 350px) and (max-width: 1000px) {
  .honeycomb-container {
    width: 100%;
    /* height: 650px; */
    padding: 0px;
    padding-top: 40px;
  }

  .hive-container {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 80px;
    background-color: transparent;
    color: var(--body_color) !important;
  }
}
