.site_footer {
    background-color: var(--main-body);
    color: var(--body_color);
}

.site_footer .col {
    justify-content: flex-start;
}

.site_footer_inner {
    padding: 0px 0 0 32px;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    max-width: 80%;
}

.site_footer_inner>div {
    flex: 1 1;
}

.social-wrapper ul {
    padding-left: 0;
}

.social-wrapper ul li {
    display: inline;
    margin: 0 20px 0 0;
}

.hive-wrapper ul {
    padding-left: 0;
}

.hive-wrapper ul li {
    display: inline;
    margin: 0 50px 0 0;
}

.footer_center_text {
    text-align: center;
    font-size: 10px;
    padding: 20px 0 0;
}

.footer-text {
    font-size: 10px;
}

.copyright_bar {
    background-color: var(--main-body);
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    max-width: 80%;
}

.row.copyright_bar {
    width: calc(100% + 1.5rem);
}

.flip_icon_container {
    position: relative;
}


.icon_container {
    position: relative;
    display: flex;
    width: 80%;
    gap: 20px;
}

.font_30x{
    font-size: 30px;
}

.flip_person_icon {
    top: -257px;
    position: absolute;
    /* right: 111px; */
    max-width: 270px;
    left: 2px;
}

.flip_woman {
    /* top: -279px; */

    top: -212px;
    max-width: 400px;
}

.copyright_text {
    font-size: 10px;
    width: fit-content;
    padding-right: 50px;
}

.fit-content {
    width: fit-content;
    padding-inline: 25px;
    font-size: 10px;
}

.border-right{
    border-right: 1px solid var(--border-color);
}
.fit-content a{
    text-decoration: none;
    color: var(--body_color);
}

.social-connect-icons{
    transition: transform 330ms ease-in-out;
    width: 29px;
    height: 29px;
}

.social-connect-icons:hover{
   
    transform: scale(1.2);
    transition: transform 330ms ease-in-out;
}

.logo_ind {
    width: 60px;
    transition: transform 330ms ease-in-out;
}

.logo_ind:hover {
    transform: scale(1.1);
    transition: transform 330ms ease-in-out;
}

.link_sty:hover{
    color: var(--dark_text);
}

.need_help {
    position: absolute;
    top: -176px;
    right: 32px;
    color: #3ac04c;
    font-size: 23px;
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 10px 14px 22px;
    cursor: pointer;
  
}
.need_help_phone {
    position: absolute;
    top: -90px;
    right: 32px;
    color: #3ac04c;
    font-size: 23px;
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 10px 14px 22px;
    cursor: pointer;
  
}


.need_help:hover {
    color: #0c7e32;
}
.need_help_phone:hover {
    color: #098d35;
}

@media (max-width: 1347px) { 

   

    .flip_person_icon {
        top: -159px;
        position: absolute;
        right: -10px;
        max-width: 340px;
        
    }
    


}

@media (max-width: 1200px) { 


    .hide-img {
        display: none;
    }


}

@media (max-width: 767px) {

    .site_footer_inner {
        flex-direction: column;
        padding-left: 0;
        max-width: 100% ;
        border: none;

    }

    .site_footer>div>div {
        display: block;
        text-align: center;
        margin-top: 50;
    }

    .site_footer>div>div.flip_icon_container {
        width: 200px;
        height: 200px;

    }
    
    .mobile-row .btn{
        box-shadow: none !important;
        border: none !important;
    }
    
    .footer-drop{
        display: flex;
        justify-content: space-between;
        width: 100%;
        transition: none !important;
    }
    
    .mobile-row{
        border-bottom: 1px solid var(--border-color) !important;
    }
    
    .border-top{
        border-top: 1px solid var(--border-color) !important;
    }

    .copyright_bar {
        max-width: 100%;
    }

    .copyright_text {
        padding-right: 0px;
    }

    .logo_ind {
        width: 110px;
    }

    .desktop-display {
        display: none;
    }
   
    .flip_person_icon_mobile {
        position: inherit;
        width: 230px;
   
        top:-60px;
        scale:1.2
    
    }

    .flip_person_icon_mobile.flip_woman {

        top: 26px;
    }

    .footercentre2 p,
    .copyright_text {
        font-size: 10px;
    }

    .hive-wrapper ul  {
        display: flex;
        margin-bottom: 20px;
    }
}

@media (min-width: 767px) { 
    .mobile-display {
        display: none;
    }
}