.data-display-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 4px;
  margin: auto;
  margin-top: 100px;  /* Adjust as necessary */
  background-color: #f9f9f9;  /* Light grey background */
}

.data-item {
  margin: 16px 0;
}

.data-item ul {
  list-style-type: none;
  padding: 0;
}

.data-item ul li {
  background-color: #e9e9e9;  /* Lighter grey for list items */
  margin: 5px 0;
  padding: 5px;
  border-radius: 4px;
}


.data-display-container  table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.data-display-container table, th, td {
  border: 1px solid #dddddd;
}

th, td {
  text-align: left;
  padding: 8px;
}

.data-display-container th {
  background-color: #f4f4f4;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}