/* 
.code:hover + .copy-icon{
    display: block;
}
.reff{
    position: relative;
}
.copy-icon{
    display: none;
}

.code:hover{
    cursor: pointer;
} */

.hexagon {
    position: relative;
    width: 123px;
    height: 127px;
    background-color: transparent;
    clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
    /* clip-path: polygon(50% 4%, 75% 24%, 85% 74%, 60% 100%, 18% 75%, 21% 14%); */
    /* clip-path: polygon(48% 4%, 75% 36%, 84% 82%, 59% 100%, 17% 63%, 20% 3%); */
    margin: 0 auto;
    overflow: hidden;
    /* transform: skewY(-21deg)    */
  }
  
  .hexagon img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
  }

  .small-hexagon {
    position: relative;
    width: 59px; /* Adjust the width as needed */
    height: 60px; /* Adjust the height as needed to achieve the desired aspect ratio */
    background-color: transparent;
    clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);

    /* clip-path: polygon(48% 4%, 76% 24%, 85% 74%, 60% 100%, 18% 75%, 21% 14%); */

    /* clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); */
    /* clip-path: polygon(48% 7%, 75% 39%, 84% 82%, 59% 100%, 17% 63%, 20% 9%);  */
    /* polygon(48% 4%, 75% 36%, 84% 82%, 59% 100%, 17% 63%, 20% 3%) */
    margin: 0 auto;
    overflow: hidden;
    /* transform: skewY(-21deg)    */
  }

  .small-hexagon img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
  }
  
  .main-hexagon {
    position: relative;
    width: 153px;
    height: 160px;
    background-color: transparent;
    clip-path: polygon(48% 4%, 76% 24%, 85% 70%, 60% 100%, 18% 75%, 21% 14%);
    /* clip-path: polygon(50% 4%, 75% 24%, 85% 74%, 60% 100%, 18% 75%, 21% 14%); */
    /* clip-path: polygon(48% 4%, 75% 36%, 84% 82%, 59% 100%, 17% 63%, 20% 3%); */
    margin: 0 auto;
    overflow: hidden;
    /* transform: skewY(-21deg)    */
  }
  
  .main-hexagon img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover the entire hexagon */
  }