.dark_mode {
  margin-top: -20px;
  margin-right: 20px;
  /* margin-top: -47px; */
  /* margin-left: 10px; */
}

.dark_mode_label {
  width: 0;
  height: 0;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 200px;
  /* box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
        inset 0px -5px 15px rgba(255, 255, 255, 0.4); */
  cursor: pointer;
  /* transition: 0.3s; */
}

.dark_mode_label:after {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  /* top: 3px; */
  /* left: 2px; */
  /* background: linear-gradient(180deg, #ffcc89, #d8860b); */
  /* background: linear-gradient(180deg,#e8dfdf, #adadad); */
  background-image: url("./dark\ 2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.dark_mode_input {
  width: 0;
  height: 0;
  visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
  background-image: url("./light\ 2.svg");
  background: #242424;
}
.dark_mode_input:checked + .dark_mode_label:after {
  /* left: 42px; */
  /* transform: translateX(-100%); */
  /* background: linear-gradient(180deg, #777, #3a3a3a); */
  background-image: url("./light\ 2.svg");
  background-size: contain;
}
.dark_mode_label:active:after {
  width: 30px;
}

.dark_mode_label svg {
  position: absolute;
  width: 20px;
  top: 5px;
  z-index: 100;
}
.dark_mode_label svg.sun {
  left: 5px;
  fill: #fff;
  transition: 0.3s;
}
.dark_mode_label svg.moon {
  left: 40px;
  fill: #7e7e7e;
  transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
  fill: #7e7e7e;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
  fill: #fff;
}
